import React from 'react';
import Helmet  from 'react-helmet'
import BogHead from '../components/BogHead';

// https://www.gatsbyjs.com/docs/using-client-side-only-packages/
const AnvsExampleLazy = React.lazy(() =>
    import("../components/BogHead")
)


const isSSR = typeof window === "undefined"

export class BasePage extends React.Component<{title?: string, className?: string, children: React.ReactNode}, {}> {

    constructor(props) {
        super(props);
    }

    public render() {
        const head = <AnvsExampleLazy className={"anvs"} key="mlp" />;
        return <div key="BASE PAGE" className={this.props.className}>
            <Helmet
                key="helm"
                title={`b_o__g ${this.props.title ? `(${this.props.title})` : ""}`}
                meta={[
                    { name: "viewport", content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"},
                    { name: 'description', content: 'My Little Putin! Slap and create your own little Putin!' },
                    { name: 'keywords', content: 'Hmwrk, homework, art, software, music, writing, experiences, wrk' },
                    { name: 'image', content: '/lol.png'}
                ]}
                > 
            </Helmet>
                {!isSSR && <React.Suspense key="SUSSY" fallback={<div key="SQUARE" />}>
                    {head}
                </React.Suspense>}
            {this.props.children}
                <div className="screen" />
        </div>
    }
}