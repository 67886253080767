import React, { Component } from 'react';

import { BasePage } from '../components/BasePage';
import { ContentCard } from '../components/ContentCard';

import logo from '../images/bog_logo.png'
import hmwrk from '../images/hmwrk logo.png'

// tslint:disable-next-line:no-var-requires
require("./index.less");

interface HomePageProps extends React.HTMLProps<HTMLDivElement> { }
interface HomePageState extends React.HTMLProps<HTMLDivElement> {
    loading: boolean,
}

export default class HomePage extends React.Component<HomePageProps, HomePageState> {

    constructor(props) {
        super(props);
    }


    
    public render() {


        return <BasePage key="Main Page" children={[
            <div key="SPLIT"  >
                <nav className='logo menu' >
                    <a className='' style={{marginLeft: "4px"}} href="/" > <img src={logo} /> </a> 
                    <a className='link' style={{marginLeft: "22px"}} title="Go to Instagram" href="https://instagram.com/b_o__g"> {"> instagram"} </a>
                    <a className='link' style={{marginLeft: "14px"}} title="My Little Putin" href="https://mylittleputin.com"> {"> my_little_putin"} </a>
                    {/* <a className='link' style={{marginLeft: "12px"}} title="My Little Putin" href="https://hmwrk.net"> {"> hmwrk.net"} </a> */}
                    <a className='link' style={{marginLeft: "9px"}} title="My Little Putin" href="https://anvs.io"> {"> anvs.io"} </a>
                    {/* <a className='link' style={{marginLeft: "7px"}} title="My Little Putin" href="https://unfound.page"> {"> unfound.page"} </a> */}
                    {/* <a className='link' style={{marginLeft: "14px"}} title="My Little Putin" href="/art"> {"> art"} </a>
                    <a className='link' style={{marginLeft: "12px"}} title="My Little Putin" href="/design"> {"> design"} </a> */}
                    <div className='cursor'/>
                </nav>
                <a className='link fixedLogo'  style={{right: "20px", bottom: "20px"}} href="https://hmwrk.net" > <img src={hmwrk} /> </a>
                

                <div className="content">
                    <ContentCard border={true} children={[
                        // {type: "Heading1", content: "God (Demon) of Bloom (Rot)"},
                        // {type: "Text", content: "This is the plague with which the BOG will strike all the nations that fought against the bloom of others."},
                        // {type: "Text", content: "Their flesh will rot while they are still standing on their feet, their eyes will rot in their sockets, and their tongues will rot in their mouths. On that day men will be stricken by the LORD with great panic. Each man will seize the hand of another, and they will attack each other."},
                        // {type: "Text", content: "A similar plague will strike the horses and mules, the camels and donkeys, and all the animals in those camps."},
                    ]}/>
                    {/* <ContentCard border={true} children={[
                        {type: "Heading1", content: "Corpus"},
                        {type: "Text", content: "Blood curdling scream as the flesh ."}
                    ]}/> */}
                </div>
            </div>
        ]} />
    }
}